import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Logo from "../../components/Logo";
import Modal from "../../components/Modal";
import { getDomains } from "../../api";

class MakeGoodLaunch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domainValue: "",
      pathUrl: "",
      domains: [],
    };
  }

  componentDidMount() {
    getDomains().then((data) => {
      if (data && data.data && data.status_code === 200) {
        let newData = [];
        if (data.data.length) {
          var unique = data.data.filter(
            (value, index, self) => self.indexOf(value) === index
          );
          unique.map((item) => {
            var pathname = item.split("//");
            if (pathname && pathname.length && pathname.length >= 1) {
              let setDomain = "."; //staging
              // let setDomain = ".makegoodapp.com" //production
              pathname = pathname[1].split(setDomain);
              newData.push({
                value: pathname.length ? pathname[0] : item,
                label: pathname.length ? pathname[0] : item,
                url: item,
              });
            } else {
              newData.push({
                value: item,
                label: item,
                url: item,
              });
            }
          });
          this.setState({ domains: newData });
        }
      }
    });
  }

  handleOnchange = (e) => {
    this.setState({ domainValue: e.target.value, showModal: false });
  };

  onContinueClick = () => {
    let { domainValue, domains } = this.state;
    if (domains && domains.length && domainValue) {
      let isPresent = false;
      domains.map((domain) => {
        if (
          domain.value &&
          domain.value.toLowerCase() == domainValue.toLowerCase()
        )
          isPresent = true;
      });

      if (isPresent) {
        let url = "https://" + domainValue + ".makegoodapp.com";
        let a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        this.setState({ showModal: true });
      }
    }
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  renderModal = () => {
    return (
      <Modal
        title="Error"
        hideModal={this.hideModal}
        closeOnClickOutside={true}
      >
        <div className="modal-content">
          The sub domain you have entered does not exist. Please try again. If
          you are unable to access your MakeGood account then please email{" "}
          <a href="mailto:support@makegoodapp.com">support@makegoodapp.com</a>{" "}
          for assistance.
        </div>
      </Modal>
    );
  };

  render() {
    const { appName } = this.props;
    const { showModal, domainValue } = this.state;

    return (
      <div className="sessions fill-screen mg-session">
        <Helmet>
          <title>{appName}</title>
        </Helmet>
        <div className={`sessions-modal modal-space`}>
          <div className="sessions-modal-logo-container centerd-content">
            <Logo circular={true} />
          </div>
          <div className="sessions-modal-content">
            <br />
            <br />
            <div className={`input-container box-select`}>
              <label htmlFor="state">Enter your MakeGood Domain to login</label>
              <div className="select-inner-div">
                <input
                  name="domain"
                  placeholder="Enter Domain"
                  type="text"
                  value={domainValue}
                  onChange={this.handleOnchange}
                />
                <div className="con-domain">.makegoodapp.com</div>
              </div>
            </div>
          </div>
          <div className="modal-actions qb-btns">
            <a
              className="cta"
              style={{
                pointerEvents: domainValue ? "auto" : "none",
              }}
              onClick={this.onContinueClick}
            >
              Continue
            </a>
          </div>
        </div>
        {showModal ? this.renderModal() : ""}
        {showModal ? <div className="curtain"></div> : ""}
      </div>
    );
  }
}

export default MakeGoodLaunch;
