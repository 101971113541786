import React, { Component } from "react";
import MakeGoodLogo from "../../src/assets/icons/MG-logo.png";

class Logo extends Component {
  render() {
    return (
      <div>
        <div
          className="companyLogoDiv"
          style={{ margin: "0 0 0 0", width: 200, float: "left" }}
        >
          <img style={{ width: 200 }} src={MakeGoodLogo} />
        </div>
      </div>
    );
  }
}

export default Logo;
