import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Logo from "../../components/Logo";
import { disconnect } from "../../api";

class MakeGoodDisconnect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  componentDidMount() {
    this.props.history.push("/disconnect");
    if (this.props.realmId) this.disconnectQB();
  }

  disconnectQB = () => {
    disconnect(this.props.realmId).then((data) => {
      if (data && data.data && data.status_code === 200) {
        //
      }
    });
  };

  render() {
    const { appName } = this.props;

    return (
      <div className="sessions fill-screen mg-session">
        <Helmet>
          <title>{appName}</title>
        </Helmet>
        <div className={`sessions-modal modal-space`}>
          <div className="sessions-modal-logo-container centerd-content">
            <Logo circular={true} />
          </div>
          <div className="sessions-modal-content-dis">
            <br />
            <label>
              You have successfully disconnected your MakeGood account from your
              QuickBooks Online account.
            </label>
            <label style={{margin: "0.8em 0em"}}>
              To reconnect your MakeGood account to your QuickBooks Online
              account:
            </label>
            <label style={{marginLeft: '2em', lineHeight: '150%'}}>
              - Login to your MakeGood account <br/>
              - Go to Settings {">"}{" "}QuickBooks<br/>
              - Select "Connect to QuickBooks"
            </label>
            <br />
            <br />
            <label>
              Please email{" "}
              <a href="mailto:support@makegoodapp.com">
                support@makegoodapp.com
              </a>{" "}
              with any questions.
            </label>
            <br />
          </div>
          {/* <div className="modal-actions qb-btns">
            <a
              className="cta"
              style={{ pointerEvents: this.state.value ? "auto" : "none" }}
              href={this.state.value}
              target="_blank"
            >
              Continue
            </a>
          </div> */}
        </div>
      </div>
    );
  }
}

export default MakeGoodDisconnect;
