let api = process.env.REACT_APP_API_URL;

export const getDomains = () =>
  fetch(`${api}/makegood/domains`)
    .then((response) => response.json())
    .catch((error) => {
      console.error("error==========:", error);
    });

export const disconnect = (realmId) =>
  fetch(`${api}/makegood/disconnectqb`, {
    method: "PUT",
    body: JSON.stringify({
      realmid: realmId,
    }),
  })
    .then((res) => res.json())
    .then(function (data) {
      return data;
    })
    .catch((error) => {
      console.error("error==========:", error);
    });
