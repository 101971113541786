import React, { Component } from "react";
import queryString from "query-string";
import { Helmet } from "react-helmet";
import { Route, BrowserRouter } from "react-router-dom";
import Favicon from "./assets/icons/favicon.ico";
import MakeGoodDisconnect from "./screens/MakeGood/MakeGoodDisconnect";
import MakeGoodLaunch from "./screens/MakeGood/MakeGoodLaunch";
import "./App.css";

class App extends Component {
  state = {};

  render() {
    return (
      <div id="app">
        <Helmet>
          <link rel="shortcut icon" href={Favicon} />
        </Helmet>
        <BrowserRouter>
          <Route
            exact
            path="/disconnect"
            render={(props) => (
              <MakeGoodDisconnect
                {...props}
                realmId={queryString.parse(props.location.search).realmid}
              />
            )}
          />
          <Route
            path="/login"
            render={(props) => <MakeGoodLaunch {...props} />}
          />
          <Route path="/" render={(props) => <MakeGoodLaunch {...props} />} />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
