import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";

class Modal extends Component {
  handleClickOutside = () => {
    if (this.props.closeOnClickOutside) {
      this.props.hideModal();
    }
  };

  closeModal = (event) => {
    event.preventDefault();
    this.props.hideModal();
  };

  render() {
    const { title, children } = this.props;
    return (
      <div className={`modal`}>
        <a className="modal-close" onClick={(event) => this.closeModal(event)}>
          <svg className="icon" viewBox="0 0 20 20">
            <path
              d="M13.7704055,10 L19.2259084,4.5444971 C20.2580305,3.51237493 20.2580305,1.82727751 19.2259084,0.774091627 C18.1937862,-0.258030542 16.5086888,-0.258030542 15.4555029,0.774091627 L10,6.22959452 L4.5444971,0.774091627 C3.51237493,-0.258030542 1.82727751,-0.258030542 0.774091627,0.774091627 C-0.258030542,1.8062138 -0.258030542,3.49131122 0.774091627,4.5444971 L6.22959452,10 L0.774091627,15.4555029 C-0.258030542,16.4876251 -0.258030542,18.1727225 0.774091627,19.2259084 C1.30068457,19.7525013 1.97472354,20.0052659 2.64876251,20.0052659 C3.32280147,20.0052659 4.01790416,19.7525013 4.52343339,19.2259084 L9.97893628,13.7704055 L15.4344392,19.2259084 C15.9610321,19.7525013 16.6350711,20.0052659 17.3091101,20.0052659 C17.983149,20.0052659 18.6782517,19.7525013 19.1837809,19.2259084 C20.2159031,18.1937862 20.2159031,16.5086888 19.1837809,15.4555029 L13.7704055,10 Z"
              fillRule="nonzero"
            />
          </svg>
        </a>
        <header>
          <h1 className={`modal-heading`}>{title}</h1>
        </header>
        {children}
      </div>
    );
  }
}

export default onClickOutside(Modal);
